import React from "react";

function App() {
  const baseUrl = "https://embed.hypertrack.com/devices";
  const publishableKey = process.env.REACT_APP_KEY;

  return (
    <React.Fragment>
      <iframe
        style={{
          width: "100vw",
          height: "100vh",
          border: "none",
        }}
        title="hyperTrack"
        src={`${baseUrl}?publishable_key=${publishableKey}`}
      />
    </React.Fragment>
  );
}

export default App;
